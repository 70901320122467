<template>
    <div style=" margin: -15px -15px;">
      <a-spin :spinning="spinning">
      <div class="live-top">
        <div class="live-top-input">
          <div>年份:</div>
          <a-date-picker
              v-model="year"
              mode="year"
              v-decorator="['year']"
              placeholder="请输入"
              format="YYYY"
              class="input"
              :open="yearShowOne"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"/>
        </div>
        <div class="live-top-input" style="width: 30%">
          <div>状态:</div>
          <a-radio-group
              style="margin-left: 5px"
              v-model="radioValue"
              @change="radioChange">
            <a-radio-button value="">
              全部
            </a-radio-button>
            <a-radio-button value="1">
              已发布
            </a-radio-button>
            <a-radio-button value="0">
              未发布
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="live-top-input">
          <div>平台:</div>
          <a-select
              @change="domainCodeChange"
              class="input"
              v-model="domainCode"
              placeholder="请选择">
            <a-select-option
                v-for="select_S in yun_subordinate_select"
                :value="select_S.domain_code">
              {{select_S.name}}
            </a-select-option>
          </a-select>
        </div>
        <div class="live-top-input">
          <div>名称:</div>
          <a-input
              v-model="titleData"
              class="input"
              placeholder="会议名称关键词" />
        </div>
        <div class="live-top-input">
          <a-button
              @click="seachBtn"
              type="primary">查询</a-button>
          <a-button
              @click="resetBtn"
              style="margin-left: 10px">重置</a-button>
        </div>
      </div>
        <div style="margin: 10px">
          <a-button @click="addTopBtn" type="primary" style="width: 100px">新增</a-button>
        </div>
      <a-table
          style="width: 98%;margin: auto"
          :rowKey='record=>record.id'
          :pagination="false"
          :columns="columns"
          :data-source="dataList">
        <span slot="beginDate" slot-scope="text, record">
              {{record.beginDate}} ~ {{ (record.endDate.replace(/\s*/g,"")).slice(5) }}
            </span>
        <span slot="functionSet" slot-scope="text, record">
          <a @click="functionSet(record)">功能设置</a>
        </span>
        <span slot="system" slot-scope="text, record">
          <a style="margin-right: 8px" @click="restBtn(record)">编辑</a>
  <!--        <a @click="promotion_Btn(record.id)"-->
  <!--            style="margin-left: 8px" >推广</a>-->
  
  
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
             更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item :disabled="record.domainCode !== 'yunicu'">
                推广
              </a-menu-item>
              <a-menu-item :disabled="record.isStatistics === '已统计'" @click="statisticsOpen(record.id,record)">
               统计
              </a-menu-item>
              <a-menu-item @click="openLive(record)" :disabled="record.isStatistics !== '已统计'">
                查看直播类评分
              </a-menu-item>
              <a-menu-item @click="openNoLive(record.id)" :disabled="record.isStatistics !== '已统计'">
                查看非直播类评分
              </a-menu-item>
            </a-menu>
          </a-dropdown>
  
  <!--        <a v-if="record.domainCode === 'yunicu'" @click="promotions(record)">推广</a>-->
  <!--        <a v-else style="pointer-events:none;color: #d7d5d5" >推广</a>-->
  
  <!--        <a style="margin-left: 8px" @click="statisticsOpen(record.id)">统计</a>-->
  <!--        -->
  <!--        <a style="margin-left: 8px" @click="statisticsOpen(record.id)">统计</a>-->
  <!--        <a style="margin-left: 8px" @click="statisticsOpen(record.id)">统计</a>-->
  
  <!--        <a @click="upBtn(record.id)"-->
  <!--            style="margin-left: 5px">上移</a>-->
  <!--        <a-->
  <!--            @click="downBtn(record.id)"-->
  <!--            style="margin-left: 5px">下移</a>-->
        </span>
        <span slot="isPublished" slot-scope="text, record">
          <a-switch
              v-model="record.isPublished === 1 ? true:false"
              checked-children="是"
              un-checked-children="否"
              @change="switchBtn(record.id,record.isPublished === 1?0:1)"
              default-checked />
        </span>
        <span slot="domainCode" slot-scope="text, record">
          <span v-if="text === 'yichimeeting'">医驰</span>
          <span v-if="text === 'yunicu'">云ICU</span>
          <span v-if="text === 'ie-learning'">IE- Learning</span>
          <span v-if="text === 'aphouse'">APnet</span>
        </span>
      </a-table>
      <div style="width: 100%">
        <div style="float: right;margin-right: 20px;margin-top: 15px;margin-bottom: 15px">
          <a-pagination
              show-quick-jumper
              :current="page_no"
              :total="total"
              @change="paginationChange" />
        </div>
      </div>
  
  <!--------------------------------------对话框------------------------>
      <a-drawer
          :get-container="false"
            @close="add_cancel"
            :width="600"
            :body-style="{ paddingBottom: '80px' }"
            :visible="add_visible"
            title="新增">
          <a-form-model
              ref="content_list_Form_add"
              :model="add_Form"
              :rules="add_Form_rules"
              :label-col="labelColAdd"
              :wrapper-col="wrapperCol"
          >
            <a-form-model-item prop="coverPics" label="封面图">
  
              <div style="display: flex;width: 450px;">
                <div style="display: flex;width: 360px;">
                  <div class="uploadImg" >
                    <input
                        id="add_upPosterCoverId"
                        style="width: 100px;height: 100px;opacity: 0;"
                        accept="image/*"
                        type="file"
                        @change="add_upPosterCover"
                        ref="add_upPosterCover_ref" />
                  </div>
                  <div
                      class="upload_posterTwo">
                    <img
                        v-if="add_Form.coverPics[0]"
                        alt=""
                        style="width: 100%;height:100%"
                        :src="add_Form.coverPics[0]"
                    />
                  </div>
                </div>
                <div style="margin-left: 10px">
                  <a @click="openSample('meetingManagement_coverPics')" style="width: 50px;margin-top: -5px;margin-left: -3px">示例图</a>
                </div>
              </div>
              <div style="color: #a1a1a1;width: 500px">注:建议尺寸1920px*1080px，宽度不能超过1920px，大小不超过2M</div>
            </a-form-model-item>
  
            <a-form-model-item  prop="name" label="会议名称">
  
              <div style="display: flex">
                <a-input v-model="add_Form.name" placeholder="请输入"/>
                <a @click="openSample('meetingManagement_name')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
              </div>
            </a-form-model-item>
  
            <a-form-model-item  prop="time" label="会议日期">
              <div style="display: flex">
                <a-range-picker v-model="add_Form.time" style="width: 300px" @change="DateChange"/>
                <a @click="openSample('meetingManagement_time')" style="width: 50px;margin-top: -5px;margin-left: 2px">示例图</a>
              </div>
            </a-form-model-item>
  
            <a-form-model-item  prop="address" label="会议地址">
              <div style="display: flex">
                <a-input style="width: 300px" v-model="add_Form.address" placeholder="请输入"/>
                <a @click="openSample('meetingManagement_address')" style="width: 50px;margin-top: -5px;margin-left: 2px">示例图</a>
              </div>
            </a-form-model-item>
  
            <a-form-model-item  prop="startTime">
  
              <template slot="label">
                <span>直播开始时间
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{tooltip}}</span>
                    </template>
                    <a-icon type="exclamation-circle" />
                  </a-tooltip>
                </span>
              </template>
  
              <div style="display: flex">
                <a-date-picker
                    style="width: 300px"
                    placeholder="请选择"
                    @change="StartChange"
                    v-model="add_Form.startTime"
                    format="YYYY-MM-DD HH:mm:ss"
                    :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                />
                <a @click="openSample('meetingManagement_startTime')" style="width: 50px;margin-top: -5px;margin-left: 2px">示例图</a>
              </div>
            </a-form-model-item>
  
            <a-form-model-item  prop="platform" label="所属平台">
              <a-select
                  class="input"
                  v-model="add_Form.platform"
                  placeholder="请选择">
                <a-select-option
                    @click="selectMode(platforms.value)"
                    v-if="platforms.name !== '全部'"
                    v-for="platforms in yun_subordinate_select"
                    :value="platforms.domain_code">
                  {{platforms.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
  
            <a-form-model-item label="会议负责人" prop="conventionHead">
              <a-select
                  show-search
                  option-filter-prop="children"
                  v-model="add_Form.conventionHead"
                  placeholder="请选择">
                <a-select-option
                    v-for="select_o in PerList"
                    v-if="select_o.status !== 'INVALID'"
                    :value="select_o.userName">
                  {{select_o.userName}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
  
            <a-form-model-item prop="leaderSales">
              <template slot="label">业务负责人 :</template>
              <a-select
                  show-search
                  option-filter-prop="children"
                  v-model="add_Form.leaderSales"
                  placeholder="请选择">
                <a-select-option
                    v-for="selectP in PerList"
                    :value="selectP.userName">
                  {{selectP.userName}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
  
            <a-form-model-item  prop="template" label="选择模版">
              <div style="display: flex">
                <a-select
                    style="width: 300px"
                    @change="templateChange"
                    class="input"
                    v-model="add_Form.template"
                    placeholder="请选择">
                  <a-select-option
                      @click="selectTem(templates.id)"
                      v-if="templates.name !== '全部'"
                      v-for="templates in template_list"
                      :value="templates.id">
                    {{templates.name}}
                  </a-select-option>
                </a-select>
                <a @click="openSample('meetingManagement_template')" style="width: 50px;margin-top: -5px;margin-left: 2px">示例图</a>
              </div>
            </a-form-model-item>
  
            <a-form-model-item label="背景图">
  
              <div style="display: flex;width: 450px;">
                <div style="display: flex;width: 250px;">
                  <div class="uploadImg" >
                    <input
                        style="width: 100px;height: 100px;opacity: 0;"
                        accept="image/*"
                        type="file"
                        @change="add_back"
                        id="add_back_id"
                        ref="add_back_ref" />
                  </div>
                  <div class="upload_posterBg">
                    <div style="position: relative" v-if="add_Form.voverImg">
                      <img alt="" style="width: 100%;height:100%" :src="add_Form.voverImg"/>
                      <a-icon @click="delImg('add_Form')" style="color: red;position: absolute;right: 3px;top: 5px" type="delete" />
                    </div>
                  </div>
                </div>
                <div style="margin-left: 10px">
                  <a @click="openSample('meetingManagement_voverImg')" style="width: 50px;margin-top: -5px;margin-left: -4px">示例图</a>
                </div>
              </div>
              <div style="color: #a1a1a1">注:宽度不能超过750px，大小不超过1M</div>
            </a-form-model-item>
  
            <a-form-model-item label="实物背景图">
              <a-switch
                  :checked="add_Form.isHomeCoverImgEntity === 1?true:false"
                  checked-children="是"
                  un-checked-children="否"
                  @change="isBgcShowSwitch(add_Form.isHomeCoverImgEntity === 0?1:0)"
                  default-checked />
            </a-form-model-item>
  
            <a-form-model-item label="主色值" >
              <a-input v-model="add_Form.mainColor">
                <input
                    :value="add_Form.mainColor"
                    type="color"
                    slot="addonBefore"
                    @change="colorAdd"
                    style="outline-style: none ;border: 0; padding: 0;height: 29px">
              </a-input>
            </a-form-model-item>
          </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="add_cancel">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="add_content_list_Ok">
            确认
          </a-button>
        </div>
        </a-drawer>
  
      <a-drawer
          title="推广"
          :width="600"
          :visible="promotion_visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="()=>{this.promotion_visible = false;
            this.promotion_form.isBeginImage = null;
            this.promotion_form.beginImageDetail = [];
            this.promotion_form.beginImageTime = '';
            this.promotion_form.isBannerShow = 0;
            this.promotion_form.bannerImage = '';
          }"
      >
        <a-form-model
            ref="promotion_ruleForm_form_two"
            :model="promotion_form"
            :rules="promotion_rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="开场图">
            <a-switch
                :checked="promotion_form.isBeginImage === 1?true:false"
                checked-children="开"
                un-checked-children="关"
                @change="promotion_Switch_btn(promotion_form.isBeginImage === 0?1:0)"
                default-checked />
          </a-form-model-item>
          <a-form-model-item prop="beginImageDetail" label="选择图片">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    style="width: 100px;height: 100px;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="promotion_upPosterCover"
                    ref="promotion_descImageUrl"
                    id="promotion_descImageUrl_id" />
              </div>
              <div
                  class="upload_poster">
                <img
                    v-if="promotion_form.beginImageDetail[0]"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="promotion_form.beginImageDetail[0]"
                />
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸宽640，大小不超过1M</div>
          </a-form-model-item>
          <a-form-model-item label="时长" prop="beginImageTime">
            <a-input v-model.number="promotion_form.beginImageTime" placeholder="建议不超过5秒"/>
          </a-form-model-item>
          <a-form-model-item label="APP 首页广告">
            <a-switch
                :checked="promotion_form.isBannerShow === 1?true:false"
                checked-children="开"
                un-checked-children="关"
                @change="openTwo_Switch_btn(promotion_form.isBannerShow === 0?1:0)"
                default-checked />
          </a-form-model-item>
  <!--        <a-form-model-item label="APP直播界面">-->
  <!--          <a-switch-->
  <!--              :checked="promotion_form.openThree === 1?true:false"-->
  <!--              checked-children="开"-->
  <!--              un-checked-children="关"-->
  <!--              default-checked />-->
  <!--        </a-form-model-item>-->
          <a-form-model-item label="广告图" prop="bannerImage">
            <div style="display: flex;width: 450px;">
              <div class="uploadImg" >
                <input
                    style="width: 100px;height: 100px;opacity: 0;"
                    accept="image/*"
                    type="file"
                    @change="promotion_upPosterCover_beginImage"
                    ref="promotion_descImageUrl_beginImage_two"
                    id="promotion_descImageUrl_beginImageId" />
              </div>
              <div
                  class="upload_poster_beginImage">
                <img
                    v-if="promotion_form.bannerImage"
                    alt=""
                    style="width: 100%;height:100%"
                    :src="promotion_form.bannerImage"
                />
              </div>
            </div>
            <div style="color: #a1a1a1">注:尺寸 1920*520，大小不超过1M</div>
          </a-form-model-item>
        </a-form-model>
  
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="()=>{
            this.promotion_visible = false;
            this.promotion_form.isBeginImage = null;
            this.promotion_form.beginImageDetail = [];
            this.promotion_form.beginImageTime = '';
             this.promotion_form.isBannerShow = 0;
            this.promotion_form.bannerImage = '';
          }">
            取消
          </a-button>
          <a-button type="primary" @click="promotion_Submit">
            确认
          </a-button>
        </div>
      </a-drawer>
  
      <a-drawer
          :get-container="false"
          @close="rest_cancel"
          :body-style="{ paddingBottom: '80px' }"
          width="600px"
          :visible="list_visible"
          title="编辑">
        <a-form-model
            ref="content_list_Form_rest"
            :model="list_Form"
            :rules="list_Form_rules"
            :label-col="labelColAdd"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="coverPics" label="封面图">
            <div style="display: flex;width: 450px;">
              <div style="display: flex;width: 360px;">
                <div class="uploadImg" >
                  <input
                      style="width: 100px;height: 100px;opacity: 0;"
                      accept="image/*"
                      type="file"
                      @change="list_Form_upPosterCover"
                      ref="list_Form_upPosterCover_ref"
                      id="list_Form_upPosterCoverId" />
                </div>
                <div
                    class="upload_posterTwo">
                  <img
                      v-if="list_Form.coverPics[0]"
                      alt=""
                      style="width: 100%;height:100%"
                      :src="list_Form.coverPics[0]"
                  />
                </div>
              </div>
              <div style="margin-left: 10px">
                <a @click="openSample('meetingManagement_coverPics')" style="width: 50px;margin-top: -5px;margin-left: -3px">示例图</a>
              </div>
            </div>
            <div style="color: #a1a1a1">注:建议尺寸1920px*1080px，宽度不能超过1920px，大小不超过2M</div>
          </a-form-model-item>
          <a-form-model-item prop="title" label="会议名称">
            <div style="display: flex">
              <a-input v-model="list_Form.title"/>
              <a @click="openSample('meetingManagement_name')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item prop="beginDate" label="会议日期">
  
            <div style="display: flex">
              <a-range-picker
                  style="width: 300px"
                  @change="picher_onChange"
                  :allowClear="false"
                  :value="[moment(list_Form.beginDate, 'YYYY-MM-DD'), moment(list_Form.endDate, 'YYYY-MM-DD')]"
                  :format="'YYYY-MM-DD'"/>
              <a @click="openSample('meetingManagement_time')" style="width: 50px;margin-top: -5px;margin-left: 2px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item prop="address" label="会议地址">
            <div style="display: flex">
              <a-input style="width: 300px" v-model="list_Form.address" placeholder="请输入"/>
              <a @click="openSample('meetingManagement_address')" style="width: 50px;margin-top: -5px;margin-left: 2px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item label="会议负责人" prop="conventionHead">
            <a-select
                show-search
                option-filter-prop="children"
                v-model="list_Form.conventionHead"
                placeholder="请选择">
              <a-select-option
                  v-for="selectP in PerList"
                  v-if="selectP.status !== 'INVALID'"
                  :value="selectP.userName">
                {{selectP.userName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="业务负责人" prop="leaderSales">
            <a-select
                show-search
                option-filter-prop="children"
                v-model="list_Form.leaderSales"
                placeholder="请选择">
              <a-select-option
                  v-for="selectY in PerList"
                  v-if="selectY.status !== 'INVALID'"
                  :value="selectY.userName">
                {{selectY.userName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item  prop="sortTime">
            <template slot="label">
                <span>直播开始时间
                <a-tooltip placement="top">
              <template slot="title">
                <span>{{tooltip}}</span>
              </template>
              <a-icon type="exclamation-circle" />
            </a-tooltip></span>
            </template>
  
            <div style="display: flex">
              <a-date-picker
                  style="width: 300px"
                  :allowClear="false"
                  v-model="list_Form.sortTime"
                  placeholder="请选择"
                  @change="startTimeChange"
                  format="YYYY-MM-DD HH:mm:ss"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              />
              <a @click="openSample('meetingManagement_startTime')" style="width: 50px;margin-top: -5px;margin-left: 2px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item  label="背景图">
  
            <div style="display: flex;width: 450px;">
              <div style="display: flex;width: 250px;">
                <div class="uploadImg" >
                  <input
                      style="width: 100px;height: 100px;opacity: 0;"
                      accept="image/*"
                      type="file"
                      @change="list_Form_back"
                      ref="list_Form_back_ref"
                      id="list_Form_backId" />
                </div>
                <div class="upload_posterBg">
                  <div style="position: relative" v-if="list_Form.homeCoverImage">
                    <img v-if="list_Form.homeCoverImage" alt="" style="width: 100%;height:100%" :src="list_Form.homeCoverImage"/>
                    <a-icon @click="delImg('list_Form')" style="color: red;position: absolute;right: 3px;top: 5px" type="delete" />
                  </div>
                </div>
              </div>
              <div style="margin-left: 10px">
                <a @click="openSample('meetingManagement_voverImg')" style="width: 50px;margin-top: -5px;margin-left: -4px">示例图</a>
              </div>
            </div>
            <div style="color: #a1a1a1">注:宽度不能超过750px，大小不超过1M</div>
          </a-form-model-item>
          <a-form-model-item label="实物背景图">
            <a-switch
                :checked="list_Form.isHomeCoverImgEntity === 1?true:false"
                checked-children="是"
                un-checked-children="否"
                @change="isEditBgcShowSwitch(list_Form.isHomeCoverImgEntity === 0?1:0)"
                default-checked />
          </a-form-model-item>
          <a-form-model-item label="主色值" >
            <a-input v-model="list_Form.mainColor">
              <input
                  :value="list_Form.mainColor"
                  type="color"
                  slot="addonBefore"
                  @change="colorRest"
                  style="outline-style: none ;border: 0; padding: 0;height: 29px">
            </a-input>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button  @click="rest_cancel">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="content_list_Ok">
            确认
          </a-button>
        </div>
      </a-drawer>
  
      <!--   推广 -->
      <a-drawer
            title="推广"
            :width="500"
            :visible="promotionsVisible"
            :body-style="{ paddingBottom: '80px' }"
            @close="promotionsClose"
        >
          <div style="margin:20px">
            <a-spin :spinning="promotionSpin">
              <a-table
                  :pagination="false"
                  :columns="appColumns"
                  :data-source="appData"
                  :row-key="record => record.code"
                  bordered>
            <span slot="is_open" slot-scope="text, record">
              <a-switch
                  v-model="record.is_open"
                  checked-children="是"
                  un-checked-children="否"
                  @click="AppListSwitch(record.is_open,record)"
                  default-checked />
            </span>
              </a-table>
            </a-spin>
          </div>
          <!--      <div style="margin:20px">-->
          <!--        <a-table :columns="communityColumns" :data-source="communityData" bordered>-->
  
          <!--        </a-table>-->
          <!--      </div>-->
          <div
              :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="promotionsClose">
              关闭
            </a-button>
          </div>
        </a-drawer>
  
      <!--   统计   -->
        <a-drawer
            :get-container="false"
            @close="statistics_cancel"
            :width="700"
            :body-style="{ paddingBottom: '80px' }"
            :visible="visible_statistics"
            title="直播总场次统计">
          <a-table
              bordered
              style="width: 100%;margin: auto"
              :rowKey='record=>record.id'
              :pagination="false"
              :columns="columnsModel"
              :data-source="dataModel">
            <template slot="number" slot-scope="text, record">
              <div class="numberInput">
                <div class="left" :style="{backgroundColor:record.number > 0 ? '' : '#F0F2F5'}"
                     @click="subtractBtn(record)">-</div>
                <div class="mid">
                  <a-input
                      @pressEnter="pressScoreEnter(record.id)"
                      @blur="pressScoreEnter(record.id)"
                      @change="e => inputScoreChange(e.target.value, record.id, 'number')"
                      class="input"
                      v-model="text"
                  />
  <!--                {{record.content === '图片展示得分' || record.content === 'PDF展示得分' || record.content === '外链得分' ||  record.content === '企业海报得分' || record.content === '点播得分' ? '个' : record.content === '云展厅得分'?'条':'场'}}-->
                </div>
                <div class="right" @click="addBtn(record)">+</div>
              </div>
            </template>
            <template slot="unit" slot-scope="text, record">
              {{record.content === '图片展示得分' || record.content === 'PDF展示得分' || record.content === '外链得分' ||  record.content === '企业海报得分' || record.content === '点播得分' ? '个' : record.content === '云展厅得分'?'条':'场'}}
            </template>
            <template slot="score" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text.toFixed(1) : 0 }}分</span>
            </template>
          </a-table>
          <div
              :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
          >
            <a-button  @click="statistics_cancel">
              取消
            </a-button>
            <a-button style="margin-left: 10px" type="primary" @click="statistics_Ok">
              确认
            </a-button>
          </div>
        </a-drawer>
      <!--  查看直播类评分    -->
        <a-drawer
            :get-container="false"
            @close="statistics_live"
            :width="1300"
            :body-style="{ paddingBottom: '80px' }"
            :visible="visible_live"
            title="查看直播类评分">
          <a-radio-group v-model="liveTimeValue" @change="liveTimeChange">
            <a-radio-button :value="time" v-for="time in liveTimeList" :key="time">
              {{time}}
            </a-radio-button>
          </a-radio-group>
          <a-table
              bordered
              style="width: 100%;margin: auto"
              :rowKey='record=>record.id'
              :pagination="false"
              :columns="columnsLive"
              :data-source="dataLive">
            <template slot="score" slot-scope="text, record">
              <span>{{ text ? text : 0 }}分</span>
            </template>
            <template slot="TotalDemerit" slot-scope="text, record">
              <span :style="{color:record.scoreManager + record.scoreSale ? 'red':''}">{{ record.scoreManager + record.scoreSale ? record.scoreManager + record.scoreSale : 0 }}</span>
            </template>
            <template slot="scoreManager" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
            </template>
            <template slot="scoreSale" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
            </template>
            <template slot="deductManager" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
            </template>
            <template slot="deductSale" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
            </template>
            <template slot="footer" slot-scope="currentPageData">
              最终总得分：{{totalScore?totalScore.toFixed(1):0}} ， 被扣除分数：{{pointsDeducted?pointsDeducted.toFixed(1):0}}
            </template>
          </a-table>
          <div
              :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
          >
            <a-button  @click="statistics_live">
              取消
            </a-button>
          </div>
        </a-drawer>
      <!--  查看非直播类评分    -->
        <a-drawer
            :get-container="false"
            @close="statistics_noLive"
            :width="1300"
            :body-style="{ paddingBottom: '80px' }"
            :visible="visible_noLive"
            title="查看非直播类评分">
          <a-table
              bordered
              style="width: 100%;margin: auto"
              :rowKey='record=>record.id'
              :pagination="false"
              :columns="columnsNoLive"
              :data-source="dataNoLive">
            <template slot="score" slot-scope="text, record">
              <span>{{ text ? text : 0 }}分</span>
            </template>
            <template slot="TotalDemerit" slot-scope="text, record">
              <span :style="{color:record.scoreManager + record.scoreSale ? 'red':''}">{{ record.scoreManager + record.scoreSale ? record.scoreManager + record.scoreSale : 0 }}</span>
            </template>
            <template slot="scoreManager" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
            </template>
            <template slot="scoreSale" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : 0 }}</span>
            </template>
            <template slot="deductSale" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
            </template>
            <template slot="deductManager" slot-scope="text, record">
              <span :style="{color:text ? 'red':''}">{{ text ? text : '无' }}</span>
            </template>
          </a-table>
          <div
              :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
          >
            <a-button  @click="statistics_noLive">
              取消
            </a-button>
          </div>
        </a-drawer>
        <PublicSample :propList="propList"></PublicSample>
      </a-spin>
    </div>
  </template>
  
  <script>
  
  import {
    CaseLiveNew,
    get_per_OfThe_one_list,
    put_per_OfThe_one_list_ofNumber,
    putSwitch,
    upDown
  } from "@/service/MedicalConference";
  import {update} from "@/utils/update";
  import {subordinate} from "@/utils/subordinateList"
  import moment from "moment";
  import {getTemplateColorPicMeet, getTemplateTitle, postConvention, putImgs} from "@/service/medicalConference_api";
  import {
    delAppPromotion,
    getAppPromotion,
    getColumnVodsList,
    postAppPromotion
  } from "@/service/MedicalConference_y";
  import {current_date} from '@/utils/currentDate'
  import PublicSample from "@/utils/PublicSample";
  import {getCommunictionList, statisticsConventionScore, submitToStatistics} from "@/service/liveMeet_api";
  import {getDateByConventionId, getLiveScoreRecordsByConventionId, getRecordsByIdAndDate} from "@/service/rating";
  const columns = [
    {
      title: '会议名称',
      dataIndex: 'title',
      key: 'title',
      width:'35%',
      ellipsis: true,
    },
    {
      title: '会议地址',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '会议日期',
      dataIndex: 'beginDate',
      key: 'beginDate',
      width:'20%',
      scopedSlots: { customRender: 'beginDate' },
    },
    {
      title: '是否发布',
      dataIndex: 'isPublished',
      key: 'isPublished',
      scopedSlots: { customRender: 'isPublished' },
    },{
      title: '平台',
      dataIndex: 'domainCode',
      key: 'domainCode',
      width:'10%',
      scopedSlots: { customRender: 'domainCode' },
    },
    {
      title: '功能设置',
      dataIndex: 'functionSet',
      key: 'functionSet',
      scopedSlots: { customRender: 'functionSet' },
    },
    {
      title: '操作',
      dataIndex: 'system',
      key: 'system',
      width:'13%',
      scopedSlots: { customRender: 'system' },
    },
  ];
  
  export default {
    components:{
      PublicSample
    },
    data(){
      return{
        propList:{
          visible:false,
          code:null,
        },
        add_visible:false,
        add_Form:{
          voverImg:'',
          mainColor:'#45a5e5',
          coverPics: [],
          name:'',
          time:[],
          address:'',
          startTime:'',
          platform:undefined,
          template:undefined,
          isHomeCoverImgEntity:0,
          conventionHead:undefined,
          leaderSales:undefined,
        },
        add_Form_rules:{
          coverPics: [{ required: true, message: '请上传', trigger: 'blur' }],
          name: [{ required: true, message: '请输入', trigger: 'blur' }],
          time: [{ required: true, message: '请选择', trigger: 'blur' }],
          address: [{ required: true, message: '请输入', trigger: 'blur' }],
          startTime: [{ required: true, message: '请选择', trigger: 'blur' }],
          platform: [{ required: true, message: '请选择', trigger: 'blur' }],
          template: [{ required: true, message: '请选择', trigger: 'blur' }],
          leaderSales: [{ required: true, message: '请选择', trigger: 'blur' }],
          conventionHead: [{ required: true, message: '请选择', trigger: 'blur' }],
        },
        // year:'2022-01-01 00:00:00',
        year:current_date(),
        yearShowOne:false,
        size: 'default',
        promotion_visible:false,
        labelColAdd: { span: 6},
        labelCol: { span: 4},
        wrapperCol: { span: 14 },
        radioValue:'',
        titleData:'',
        dataList:[],
        columns,
        page_no:1,
        page_size:10,
        total:null,
        spinning:false,
        promotion_form:{
          isBeginImage:0,
          beginImageDetail:[],
          beginImageTime:null,
          isBannerShow:0,
          bannerImage:'',
        },
        promotion_rules: {
          beginImageDetail: [{ required: true, message: '请上传', trigger: 'blur' }],
          beginImageTime: [{ required: true, message: '请输入', trigger: 'blur' }],
          bannerImage: [{ required: true, message: '请上传', trigger: 'blur' }],
        },
        listId:'',
        list_visible:false,
        list_Form:{
          title:"",
          address:"",
          beginDate:'',
          endDate:'',
          mainColor:'',
          coverPics:[],
          homeCoverImage:'',
          sortTime:'',
          isHomeCoverImgEntity:0,
          conventionHead:undefined,
          leaderSales:undefined,
        },
        list_Form_rules: {
          title: [{ required: true, message: '请输入', trigger: 'blur' }],
          address: [{ required: true, message: '请输入', trigger: 'blur' }],
          beginDate: [{ required: true, message: '请选择', trigger: 'blur' }],
          coverPics: [{ required: true, message: '请上传', trigger: 'blur' }],
          sortTime: [{ required: true, message: '请选择', trigger: 'blur' }],
          conventionHead: [{ required: true, message: '请选择', trigger: 'blur' }],
          leaderSales: [{ required: true, message: '请选择', trigger: 'blur' }],
        },
        domainCode:'',
        template_list:[],
        templateListUrl:'',
        optionValue:'',
        yun_subordinate_select:subordinate(),
        promotionsVisible:false,
        appColumns:[
          {
            title: 'APP首页',
            key: 'title',
            dataIndex: 'title',
          },
          {
            title: '状态',
            dataIndex: 'is_open',
            key: 'is_open',
            scopedSlots: { customRender: 'is_open' },
          },
        ],
        appData:[],
        columnListApp:[],
        promotionId:null,
        promotionSpin:false,
        tooltip:'用于会议列表页倒计时和排序',
        visible_statistics:false,
        StatisticsId:null,
        statisticsEndDate:null,
        columnsModel: [
          {
            title: '得分内容',
            dataIndex: 'content',
            key: 'content',
            ellipsis: true,
            customRender (_, row) {
              return {
                children: row.content,
                attrs: {
                  rowSpan: row.contentRowSpan
                }
              }
            }
          },
          {
            title: '负责人',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
          },
          {
            title: '场次',
            dataIndex: 'number',
            key: 'number',
            scopedSlots: { customRender: 'number' },
          },{
            title: '单位',
            width:'80px',
            scopedSlots: { customRender: 'unit' },
          },
          {
            title: '得分',
            dataIndex: 'score',
            key: 'score',
            scopedSlots: { customRender: 'score' },
          },
        ],
        dataModel : [],
        cacheData:[],
        editingKey: '',
        PerList:[],
        visible_live:false,
        liveOpenId:null,
        visible_noLive:false,
        columnsLive: [
          {
            title: '类型',
            dataIndex: 'largeCategory',
            key: 'largeCategory',
            ellipsis: true,
            customRender (_, row) {
              return {
                children: row.largeCategory,
                attrs: {
                  rowSpan: row.largeCategoryRowSpan
                }
              }
            }
          },
  
          {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
          },{
            title: '负责人',
            dataIndex: 'scoreName',
            key: 'scoreName',
          },{
            title: '内容',
            dataIndex: 'subclass',
            key: 'subclass',
            ellipsis: true,
          },
          {
            title: '得分值',
            dataIndex: 'score',
            key: 'score',
            scopedSlots: { customRender: 'score' },
          },
          {
            title: '总扣分',
            key: 'TotalDemerit',
            scopedSlots: { customRender: 'TotalDemerit' },
          },
          {
            title: '主管扣分',
            dataIndex: 'scoreManager',
            key: 'scoreManager',
            scopedSlots: { customRender: 'scoreManager' },
          },{
            title: '主管扣分理由',
            dataIndex: 'deductManager',
            key: 'deductManager',
            scopedSlots: { customRender: 'deductManager' },
          },{
            title: '业务扣分',
            dataIndex: 'scoreSale',
            key: 'scoreSale',
            scopedSlots: { customRender: 'scoreSale' },
          },{
            title: '业务扣分理由',
            dataIndex: 'deductSale',
            key: 'deductSale',
            scopedSlots: { customRender: 'deductSale' },
          },
        ],
        totalScore:0,
        pointsDeducted:0,
        dataLive : [],
        liveTimeValue:null,
        liveTimeList:[],
        columnsNoLive: [
          {
            title: '类型',
            dataIndex: 'largeCategory',
            key: 'largeCategory',
            ellipsis: true,
            customRender (_, row) {
              return {
                children: row.largeCategory,
                attrs: {
                  rowSpan: row.largeCategoryRowSpan
                }
              }
            }
          },
          {
            title: '内容',
            dataIndex: 'subclass',
            key: 'subclass',
          },
          {
            title: '得分人姓名',
            dataIndex: 'scoreName',
            key: 'scoreName',
          },
          {
            title: '得分值',
            dataIndex: 'score',
            key: 'score',
            scopedSlots: { customRender: 'score' },
          },
          {
            title: '总扣分',
            // dataIndex: 'TotalDemerit',
            key: 'TotalDemerit',
            scopedSlots: { customRender: 'TotalDemerit' },
          },{
            title: '主管扣分',
            dataIndex: 'scoreManager',
            key: 'scoreManager',
            scopedSlots: { customRender: 'scoreManager' },
          },{
            title: '主管扣分理由',
            dataIndex: 'deductManager',
            key: 'deductManager',
            scopedSlots: { customRender: 'deductManager' },
          },{
            title: '业务扣分',
            dataIndex: 'scoreSale',
            key: 'scoreSale',
            scopedSlots: { customRender: 'scoreSale' },
          },{
            title: '业务扣分理由',
            dataIndex: 'deductSale',
            key: 'deductSale',
            scopedSlots: { customRender: 'deductSale' },
          },
        ],
        dataNoLive : [],
      }
    },
    created() {
      this.$store.dispatch('setManageHeaderTitle',"会议管理")
      this.getLiveList()
      //  加载模板列表
      this.getTemList()
      this.getPerList()
    },
    mounted() {
    },
    methods:{
      async openLive(row) {
        this.liveOpenId = row.id
        await this.getLiveTimeList(row.id)
        await this.getRecordsByIdAndDates(row.id)
        this.visible_live = true;
      },
      //获取直播类分数统计
      async getRecordsByIdAndDates(conventionId) {
        let data = {
          date:this.liveTimeValue,
          conventionId:conventionId,
        }
        const response = await getRecordsByIdAndDate(data)
        if (response.code === 0) {
          this.dataLive = response.data
          this.getTotal(response.data)
          this.mergeRowCellLive(this.dataLive)
        } else {
          this.$message.warning(response.message)
        }
      },
      //获取总得分和总扣除分数
      getTotal(data){
        this.totalScore = 0;
        this.pointsDeducted = 0;
        //获取总得分
        for (let i=0;i<data.length;i++){
          this.totalScore = this.totalScore + data[i].score
          this.pointsDeducted = this.pointsDeducted + data[i].scoreManager + data[i].scoreSale
  
        }
      },
      //获取医学会议下的直播评分时间
      async getLiveTimeList(conventionId) {
        const response = await getDateByConventionId(conventionId)
        if (response.code === 0) {
          this.liveTimeList = response.data
          this.liveTimeValue = response.data[0]
          if(response.data.length === 0){
            this.$message.info("请先创建直播间！")
          }
        } else {
          this.$message.warning(response.message)
        }
      },
      liveTimeChange(){
        this.getRecordsByIdAndDates(this.liveOpenId)
      },
      openNoLive(id) {
        this.getLiveScoreRecordsByConvention(id);
        this.visible_noLive = true;
      },
      //获取非直播类分数统计
      async getLiveScoreRecordsByConvention(conventionId) {
        const response = await getLiveScoreRecordsByConventionId(conventionId)
        if(response.code === 0){
          this.dataNoLive = response.data
          this.mergeRowCellNOLive(this.dataNoLive)
        }
      },
      mergeRowCellNOLive (data) {
        let tableData = this.rowSpan('largeCategory', data)
        this.dataNoLive = tableData
      },
      statistics_live(){
        this.visible_live = false;
      },
      statistics_noLive(){
        this.visible_noLive = false;
      },
      // 合并单元格
      rowSpan (key, data) {
        const arr = data
            .reduce((result, item) => {
              if (result.indexOf(item[key]) < 0) {
                result.push(item[key])
              }
              return result
            }, [])
            .reduce((result, keys) => {
              const children = data.filter(item => item[key] === keys)
              result = result.concat(
                  children.map((item, index) => ({
                    ...item,
                    [`${key}RowSpan`]: index === 0 ? children.length : 0
                  }))
              )
              return result
            }, [])
        return arr
      },
      // 表格合并
      mergeRowCell (data) {
        let tableData = this.rowSpan('content', data)
        this.dataModel = tableData
      },
      mergeRowCellLive (data) {
        let tableData = this.rowSpan('largeCategory', data)
        this.dataLive = tableData
      },
  
      openSample(code){
        // code加上时间防止重复
        this.propList = {
          visible:true,
          code:code+'202304031442',
        }
      },
      //获取会议列表
      async getLiveList() {
        this.spinning = true
        let data = {
          page_no:this.page_no,
          page_size:this.page_size,
          isPublished:this.radioValue,
          title:this.titleData,
          sortTime:this.year,
          domainCode:this.domainCode
        }
        const response = await CaseLiveNew(data)
        if(response.code === 0){
          this.dataList = response.data.rows
          this.total = response.data.count
        }else {
          this.$message.warning(response.message)
        }
        this.spinning = false
      },
      restBtn(row){
        this.listId = row.id
        this.list_visible = true
        this.list_Form = row
      },
      picher_onChange(value, dateString){
        this.list_Form.beginDate = dateString[0]
        this.list_Form.endDate = dateString[1]
      },
      isEditBgcShowSwitch(isOpen){
        this.list_Form.isHomeCoverImgEntity = isOpen
      },
      content_list_Ok(){
        this.$refs.content_list_Form_rest.validate(async valid => {
          if (valid) {
            let form = this.list_Form
            let data = {
              title:form.title,
              address:form.address,
              beginDate:form.beginDate,
              endDate:form.endDate,
              mainColor:form.mainColor,
              coverPics:form.coverPics,
              homeCoverImage:form.homeCoverImage,
              sortTime:form.sortTime,
              isHomeCoverImgEntity:form.isHomeCoverImgEntity,
              conventionHead:form.conventionHead,
              leaderSales:form.leaderSales,
            }
            const response = await putSwitch(this.listId,data)
            if (response.code === 0) {
              this.$message.success("修改成功！")
              //关闭
              this.list_visible = false
              //  刷新
              await this.getLiveList()
            } else {
              this.$message.warning("失败!", response.message)
            }
          } else {
            return false;
          }
        });
      },
      rest_cancel(){
        this.list_visible = false;
        this.$refs.content_list_Form_rest.resetFields();
        this.getLiveList()
      },
      radioChange(){
        this.page_no=1
        this.getLiveList()
      },
      seachBtn(){
        if(this.year){
          this.year = moment(this.year).format('YYYY')
          this.year = this.year+'-01-01 00:00:00'
        }
        this.getLiveList()
      },
      resetBtn(){
        this.titleData = ''
        this.radioValue = ''
        this.page_no = 1
        this.year = ''
        this.domainCode = ''
        this.getLiveList()
      },
      paginationChange(page_no,page_size){
        this.page_no = page_no
        this.page_size = page_size
        this.getLiveList()
      },
      functionSet(row){
        this.$store.commit('convertionImg',row)
        localStorage.setItem('convertionImg',JSON.stringify(row))
  
        this.$router.push({path:'/livefeaterManagement',query:{id:row.id,liveMeetingId:row.liveMeetingId}})
      },
      //公用上移下移
      async upDown(convention_id,action) {
        const response = await upDown(convention_id,action)
        if (response.code === 0) {
          this.$message.success("操作成功！")
        //  刷新
          await this.getLiveList()
        } else {
          this.$message.warning("失败!", response.message)
        }
      },
      upBtn(id){
        let action = 'up'
        this.upDown(id,action)
      },
      downBtn(id){
        let action = 'down'
        this.upDown(id,action)
      },
      async switchBtn(id,isOpen) {
        let data = {
          "isPublished":isOpen,
        }
        const response = await putSwitch(id,data)
        if (response.code === 0) {
          this.spinning = true
          this.$message.success("操作成功！")
          //  刷新
          await this.getLiveList()
          this.spinning = false
        } else {
          this.$message.warning("失败!", response.message)
        }
      },
      //获取单个详情
      async getOneList(id) {
        const response = await get_per_OfThe_one_list(id)
        if (response.code === 0) {
          let form = this.promotion_form
          form.isBeginImage = response.data.isBeginImage
          if(response.data.beginImageDetail){
            form.beginImageDetail = response.data.beginImageDetail
          }
          form.beginImageTime = response.data.beginImageTime
          form.isBannerShow = response.data.isBannerShow
          form.bannerImage = response.data.bannerImage
        } else {
          this.$message.warning("失败!", response.message)
        }
      },
      promotion_Switch_btn(isOpen){
        this.promotion_form.isBeginImage = isOpen
      },
      openTwo_Switch_btn(isOpen){
        this.promotion_form.isBannerShow = isOpen
      },
      promotion_Btn(id){
        this.listId = id
        this.promotion_visible = true
        this.getOneList(id)
      },
      //修改公用
      async restGong(data) {
        const response = await put_per_OfThe_one_list_ofNumber(this.listId, data)
        if (response.code === 0) {
          this.$message.success("修改成功!")
        //  刷新
          await this.getOneList(this.listId)
  
        } else {
          this.$message.warning("失败!", response.message)
        }
      },
      promotion_Submit(){
        this.$refs.promotion_ruleForm_form_two.validate(valid => {
          if (valid) {
            let form = this.promotion_form
            let data = {
              "isBeginImage":form.isBeginImage,
              "beginImageTime": form.beginImageTime,
              "beginImageDetail": form.beginImageDetail,
              "isBannerShow":form.isBannerShow,
              "bannerImage":form.bannerImage
            }
            this.promotion_visible = false
            this.restGong(data)
            this.promotion_form.isBeginImage = null;
            this.promotion_form.beginImageDetail = [];
            this.promotion_form.beginImageTime = '';
            this.promotion_form.isBannerShow = 0;
            this.promotion_form.bannerImage = '';
          } else {
            this.$message.warning("有空~~")
            return false;
          }
        });
      },
      async promotion_upPosterCover() {
        let inputDOM = this.$refs.promotion_descImageUrl.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            // this.promotion_form.beginImageDetail[0] = image.data.url
            this.$set(this.promotion_form.beginImageDetail,0,image.data.url)
            this.$message.success("上传成功")
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('promotion_descImageUrl_id').value = null
      },
      async promotion_upPosterCover_beginImage() {
        let inputDOM = this.$refs.promotion_descImageUrl_beginImage_two.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.promotion_form.bannerImage = image.data.url
            this.$message.success("上传成功")
  
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('promotion_descImageUrl_beginImageId').value = null
      },
      colorAdd(e){
        this.add_Form.mainColor = e.target.value
      },
      colorRest(e){
        this.list_Form.mainColor = e.target.value
      },
      startTimeChange(data){
        this.list_Form.sortTime = moment(data).format('YYYY-MM-DD HH:mm:ss')
      },
      moment,
      async list_Form_upPosterCover() {
        let form = this.list_Form
        let _this = this
        let inputDOM = this.$refs.list_Form_upPosterCover_ref.files[0];
        //判断图片大小
        if (inputDOM.size < 2097152) {
          //判断图片尺寸
          if (inputDOM) {
            var reader = new FileReader()
            reader.onload = function (event) {
              var base64 = event.target.result
              var img = document.createElement('img')
              img.src = base64
              img.onload = async function () {
                if (img.width <= 1920) {
                  const image = await update(inputDOM)
                  if (image.code === 0) {
                    // this.list_Form.coverPics[0] = image.data.url
                    _this.$set(form.coverPics,0,image.data.url)
                    _this.$message.success("上传成功")
  
                  } else {
                    _this.$message.warning(image.message)
                  }
                } else {
                  _this.$message.warning("注:建议尺寸1920px*1080px，宽度不能超过1920px !")
                }
              }
            }
            reader.readAsDataURL(inputDOM)
          }
        } else {
          this.$message.warning("请上传小于2M的图片！")
        }
        document.getElementById('list_Form_upPosterCoverId').value = null
      },
      async list_Form_back() {
        let _this = this
        let form = this.list_Form
        let inputDOM = this.$refs.list_Form_back_ref.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          //判断图片尺寸
          if (inputDOM) {
            var reader = new FileReader()
            reader.onload = function (event) {
              var base64 = event.target.result
              var img = document.createElement('img')
              img.src = base64
              img.onload = async function () {
                if (img.width <= 750) {
                  const image = await update(inputDOM)
                  if (image.code === 0) {
                    form.homeCoverImage = image.data.url
                    _this.$message.success("上传成功")
                  } else {
                    _this.$message.warning(image.message)
                  }
                } else {
                  _this.$message.warning("注:宽度不能超过750px !")
                }
              }
            }
            reader.readAsDataURL(inputDOM)
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('list_Form_backId').value = null
      },
      domainCodeChange(){
        if(this.year){
          this.year = moment(this.year).format('YYYY')
          this.year = this.year+'-01-01 00:00:00'
        }
        this.getLiveList()
      },
      // 弹出日历和关闭日历的回调
      openChangeOne(status) {
        if (status) {
          this.yearShowOne = true;
        }else {
          this.yearShowOne = false;
        }
      },
      // 得到年份选择器的值
      panelChangeOne(value) {
        this.yearShowOne = false;
        this.year = value;
        this.page_no = 1
        if(this.year){
          this.year = moment(this.year).format('YYYY')
          this.year = this.year+'-01-01 00:00:00'
        }
        this.getLiveList()
      },
      addTopBtn(){
        this.add_visible = true
      },
      DateChange(date, dateString) {
        this.add_Form.time = dateString
      },
      StartChange(date, dateString){
        this.add_Form.startTime = moment(date).format('YYYY-MM-DD HH:mm:ss')
      },
      selectMode(value){
        this.optionValue = value
      },
      templateChange(){
        console.log(this.add_Form.template)
      },
      async add_upPosterCover() {
        let form = this.add_Form;
        let _this = this;
        let inputDOM = this.$refs.add_upPosterCover_ref.files[0];
        //判断图片大小
        if (inputDOM.size < 2097152) {
          //判断图片尺寸
          if (inputDOM) {
            var reader = new FileReader()
            reader.onload = function (event) {
              var base64 = event.target.result
              var img = document.createElement('img')
              img.src = base64
              img.onload = async function () {
                if (img.width <= 1920) {
                  const image = await update(inputDOM)
                  if (image.code === 0) {
                    // this.add_Form.coverPics[0] = image.data.url
                    _this.$set(form.coverPics,0,image.data.url)
                    _this.$message.success("上传成功")
                  } else {
                    _this.$message.warning("上传失败")
                  }
                } else {
                  _this.$message.warning("注:建议尺寸1920px*1080px，宽度不能超过1920px !")
                }
              }
            }
            reader.readAsDataURL(inputDOM)
          }
        } else {
          this.$message.warning("请上传小于2M的图片！")
        }
        document.getElementById('add_upPosterCoverId').value = null
      },
      async add_back() {
        let form = this.add_Form;
        let _this = this
        let inputDOM = this.$refs.add_back_ref.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          //判断图片尺寸
          if (inputDOM) {
            var reader = new FileReader()
            reader.onload = function (event) {
              var base64 = event.target.result
              var img = document.createElement('img')
              img.src = base64
              img.onload = async function () {
                if (img.width <= 750) {
                  const image = await update(inputDOM)
                  if (image.code === 0) {
                    _this.add_Form.voverImg = image.data.url
                    _this.$message.success("上传成功")
                  } else {
                    _this.$message.warning("上传失败")
                  }
                } else {
                  _this.$message.warning("注:宽度不能超过750px !")
                }
              }
            }
            reader.readAsDataURL(inputDOM)
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
        document.getElementById('add_back_id').value = null
      },
      isBgcShowSwitch(isOpen){
        this.add_Form.isHomeCoverImgEntity = isOpen
      },
      async delImg(type) {
        if (type === 'add_Form') {
          this.add_Form.voverImg = ''
        } else if (type === 'list_Form') {
          //修改的删除是将list中的图片删除
          let id = this.listId*1
          const image = await putImgs(id)
          if (image.code === 200) {
            this.$message.success("图片删除成功~")
          } else {
            this.$message.warning(image.message)
          }
          this.list_Form.homeCoverImage = ''
        }
      },
      async add_content_list_Ok() {
        this.$refs.content_list_Form_add.validate(async valid => {
        if (valid) {
          let form = this.add_Form
          const data = {
            coverPics: form.coverPics,
            mainColor: form.mainColor,
            homeCoverImage: form.voverImg,
            tempUrl: this.optionValue + this.templateListUrl,
            title: form.name,
            address: form.address,
            beginDate: moment(form.time[0]).format('YYYY-MM-DD'),
            endDate: moment(form.time[1]).format('YYYY-MM-DD'),
            domainCode: form.platform,
            sortTime: form.startTime,
            // sortTime:new Date(form.startTime)
            isHomeCoverImgEntity:form.isHomeCoverImgEntity,
            conventionHead:form.conventionHead,
            leaderSales:form.leaderSales,
          }
          await this.post_convention(data)
          this.add_cancel()
          //  刷新列表
          await this.getLiveList()
        } else {
          return false;
        }
      });
      },
      add_cancel(){
        //  关闭对话框
        this.add_visible = false
        this.$refs.content_list_Form_add.resetFields();
        this.add_Form.time = []
        this.add_Form.isHomeCoverImgEntity = 0
        this.add_Form.voverImg = ''
        this.add_Form.startTime = ''
        this.add_Form.mainColor = '#45a5e5'
      },
    //  获取列表 --- 模板列表
      async getTemList() {
        const Info = await getTemplateTitle()
        if(Info.code === 0){
          // this.template_list = response.data
          for (let i = 0 ; i < Info.data.length ; i++) {
            if (Info.data[i].isOpen === 1){
              this.template_list.push({
                id: Info.data[i].id,
                isBanner: Info.data[i].isBanner,
                isBgimg: Info.data[i].isBgimg,
                isNotice: Info.data[i].isNotice,
                isOpen: Info.data[i].isOpen,
                name: Info.data[i].name
              })
            }
          }
        }
      },
      // 获取列表 --- 模板列表中的url
      async getTemList_url(id) {
        const response = await getTemplateColorPicMeet(id)
        if(response.code === 0){
          this.templateListUrl = response.data.url
        }
      },
      //新增医学会议列表
      async post_convention(data) {
        const response = await postConvention(data)
        console.log(response)
        if(response.code === 0){
          this.$message.success("新增成功~")
        }else {
          this.$message.warning(response.message)
        }
      },
      selectTem(id){
      //  通过选择的id获取url的后半截
        this.getTemList_url(id)
      },
      promotionsClose(){
        this.promotionsVisible = false
        this.appData = []
        this.promotionId = null
      },
      async AppListSwitch(isOpen, row) {
        //推广
        if(isOpen){
          let data = [
            {
              contentId: this.promotionId,//内容id
              contentType: "LIVE_METTING",//一级内容
              columnCode: row.code,//栏目
              subContentType: "CONVENTION"//子级内容
            }
          ]
          await this.postAppSwitch(data,row)
        }else { //关闭推广
          await this.delAppSwitch(this.promotionId,row.code,'app',row)
        }
      },
      async delAppSwitch(id,code,type,row) {
        let data = {
          code:code,
          type:type
        }
        const response = await delAppPromotion(id,data)
        if (response.code === 0) {
          //更新数据
          this.appData.forEach((a,index) => {
            if (a.code === row.code) {
              this.$set(this.appData, index, a)
              return
            }
          })
          this.$message.success("关闭成功~")
        } else {
          this.$message.warning(response.message)
        }
      },
      async postAppSwitch(data,row) {
        const response = await postAppPromotion(data)
        if (response.code === 0) {
          //更新数据
          this.appData.forEach((a,index) => {
            if (a.code === row.code) {
              this.$set(this.appData, index, a)
              return
            }
          })
          this.$message.success("推广成功~")
        } else {
          this.$message.warning(response.message)
        }
      },
      //获取栏目
      async getColumnList(id) {
        this.promotionSpin = true
        this.appData = []
        const response = await getColumnVodsList()
        if (response.code === 0) {
          this.appData = response.data
          await this.getAppList(id)
        } else {
          this.$message.warning(response.message)
        }
        this.promotionSpin = false
      },
      async promotions(list) {
          this.promotionId = list.id
          this.getColumnList(list.id)
          this.promotionsVisible = true
      },
      async getAppList(id) {
        const response = await getAppPromotion(id)
        if (response.code === 0) {
          this.columnListApp = response.data
          //对比
          let app = this.appData
          let columnList = this.columnListApp
          app.forEach((a,index) =>{
            a.is_open = columnList.some(b =>{
              return b.code === a.code
            })
            this.$set(app,index,a)
          })
        } else {
          this.$message.warning(response.message)
        }
      },
      async statisticsOpen(id,row) {
        this.statisticsEndDate = row.endDate
        await this.getStatistics(id);
        this.StatisticsId = id;
        this.visible_statistics = true;
      },
      //获取原始会议场次得分
      async getStatistics(id) {
        //销售部 8994250
        const response = await statisticsConventionScore(id)
        if(response.code === 0){
          this.dataModel = response.data
          this.cacheData = this.dataModel.map(item => ({ ...item }));
          this.mergeRowCell(this.dataModel)
        }
      },
      inputScoreChange(value, id, column){
        const newData = [...this.dataModel];
        const target = newData.find(item => id === item.id);
        if (target) {
          if(value >=0){
            target[column] = value;
            target.score = target.number * target.typeScore
            this.dataModel = newData;
          }else {
            this.$message.warning("请输入大于等于0的数字！")
          }
        }
        this.getTotal(newData)
      },
      pressScoreEnter(id){ //回车操作，保存编辑内容
        const newData = [...this.dataModel];
        const newCacheData = [...this.cacheData];
        const target = newData.find(item => id === item.id);
        const targetCache = newCacheData.find(item => id === item.id);
        if (target && targetCache) {
          this.dataModel = newData;
          Object.assign(targetCache, target);
          this.cacheData = newCacheData;
        }
        this.editingKey = '';
      },
      statistics_cancel(){
        this.visible_statistics = false
      },
      subtractBtn(record){
        for (let i=0;i<this.dataModel.length;i++){
          if(record.id === this.dataModel[i].id && this.dataModel[i].number > 0){
            this.dataModel[i].number-- //场次
            this.$set(this.dataModel[i],"score",this.dataModel[i].score - record.typeScore)
          }
        }
      },
      addBtn(record){
        for (let i=0;i<this.dataModel.length;i++){
          if(record.id === this.dataModel[i].id){
            this.dataModel[i].number++ //场次
            this.$set(this.dataModel[i],"score",this.dataModel[i].score + record.typeScore)
          }
        }
      },
      judgeTime(time) {
        var date1 = time;
        //现在时间
        var date2 = moment(new Date()).format('YYYY-MM-DD')
  
        console.log(date1 , date2 , date1 === date2 , date1 > date2)
        //判断时间是否过期
        return date1 <= date2;
      },
      async statistics_Ok() {
        //判断这场大会时候结束，只有结束的大会才可以统计
        if(this.judgeTime(this.statisticsEndDate)){
          //查看 - 得分内容的分类场次总和是否等于总负责人场次，相等则点击确认成功，不等则提示编辑信息
          let contentType = []; //场次类型集合
          let sunNumber; //总场次
          let isNtxt = true; //
          let data = this.dataModel;
          for (let i = 0; i < data.length; i++) { //获取场次类型集合
            if (contentType.indexOf(data[i].content) === -1) { //判断数组中是否有这个类型
              contentType.push(data[i].content);
            }
  
            if (data[i].content === '总负责人得分') {
              sunNumber = data[i].number //总场次
            }
          }
          for (let k = 0; k < contentType.length; k++) {
            let sum = 0;
            for (let j = 0; j < data.length; j++) {
              if (data[j].content === contentType[k]) {
                sum = sum + data[j].number;
              }
            }
            //只有机动得分，日程得分之和不等于总场次的
            if(contentType[k] === '日程日历得分' || contentType[k] === '机动负责人得分'){
              if (sum !== sunNumber) { //若有场次类型总和不等于总场次的，终端循环，提示编辑场次信息
                this.$message.warning(contentType[k] + "与总负责人场次不符，请检查！")
                isNtxt = false;
                return
              }
            }
          }
          if (isNtxt) {
            for (let f = 0; f < data.length; f++) {
              this.$set(data[f],'conventionId',this.StatisticsId)
              delete data[f].id;
            }
            const response = await submitToStatistics(data)
            if (response.code === 200) {
              this.$message.success("统计成功~")
              await this.getLiveList()
              this.visible_statistics = false;
            }
          }
        }else {
         this.$message.warning("大会未结束，无法进行统计结算！")
        }
      },
      //获取公司人员列表
      async getPerList(departmentById) {
        //销售部 8994250
        const response = await getCommunictionList(1,9999,1,'',departmentById)
        if(response.code === 0){
          this.PerList = response.data.rows
        }
      },
  
  
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .live-top{
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    border-bottom: 10px solid #f5f5f5;
    padding-top: 15px;
    .live-top-input{
      width: 25%;
      display: flex;
      height: 35px;
      line-height: 35px;
      padding-left: 30px;
      .input{
        width: 65%;
        margin-left: 5px;
      }
    }
  }
  .uploadImg{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 120px;
    background-image: url("../../../assets/add.png");
    background-size:100% 100%;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  .upload_poster{
    border: #DDDDDD 2px dashed;
    width:110px;
    min-height: 120px;
    margin-left: 20px;
    text-align: center;
  }.upload_posterBg{
    border: #DDDDDD 2px dashed;
    width:120px;
    height: auto;
    margin-left: 20px;
    text-align: center;
  }
  .upload_posterTwo{
    border: #DDDDDD 2px dashed;
    width:240px;
    height: 135px;
    margin-left: 20px;
    text-align: center;
  }
  .upload_poster_beginImage{
    border: #DDDDDD 2px dashed;
    width:192px;
    height: 52px;
    min-height: 120px;
    margin-left: 20px;
    text-align: center;
  }
  //.numberInput{
  //  display: flex;height: 35px;line-height: 35px;border: 1px solid #d6d6d7;width: 120px;
  //  .left{
  //    width: 35px;
  //    text-align: center;
  //    cursor: pointer;
  //    border-right: 1px solid #f2f2f5;
  //  }
  //  .mid{
  //    width: 80px;
  //    text-align: center;
  //    //border: 1px solid #45A5E6;
  //    color: #45A5E6;
  //  }
  //  .right{
  //    width: 35px;
  //    text-align: center;
  //    cursor: pointer;
  //    border-left: 1px solid #f2f2f5;
  //    //background-color: #F0F2F5;
  //  }
  //}
  .numberInput{
    display: flex;height: 30px;line-height: 30px;border: 1px solid #acadaf;width: 110px;
    .left{
      width: 30px;text-align: center;cursor: pointer;
    }
    .mid{
      width: 50px;
      .input{
        width: 50px;height: 27px;text-align: center;color: #45A5E6;
      }
    }
    .right{
      width: 30px;
      text-align: center;
      cursor: pointer;
      //background-color: #F0F2F5;
    }
  }
  </style>
  